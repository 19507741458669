<!-- 电子游戏 -->
<template>
  <div class="guide-wrapper">
    <div class="guide-head">
      <div class="head-inner">
        <h3 class="guide-head-title">HƯỚNG DẪN NẠP RÚT</h3>
        <p class="guide-head-desc">
          Bạn đã có tài khoản tại 888b.com.Bạn muốn nạp tiền vào để chơi thật ăn
          tiền thật. Bài viết này đây chúng tôi sẽ hướng dẫn cho bạn những hình
          thức nạp tiền tại trang 888b đơn giản - dễ hiểu nhất.
        </p>
        <ul class="guide-head-list">
          <li class="guide-li">
            <img src="../../assets/images/km/list-1.png" alt="" />
          </li>
          <li class="guide-li">
            <img src="../../assets/images/km/right.png" />
          </li>
          <li class="guide-li">
            <img src="../../assets/images/km/list-3.png" alt="" />
          </li>
          <li class="guide-li">
            <img src="../../assets/images/km/right.png" />
          </li>
          <li class="guide-li">
            <img src="../../assets/images/km/list-5.png" alt="" />
          </li>
        </ul>
        <div class="notice-wrapper">
          <div class="notice-item left">
            <h4 class="notice-title">NẠP RÚT TRỰC TIẾP</h4>
            <ul class="notice-list">
              <li class="notice-li">
                Ưu điểm 1: Chỉ cần hội viên đảm bảo kết nối mạng của mình là ổn
                định, điểm sẽ được cập nhật ngay lập tức.
              </li>
              <li class="notice-li">
                Ưu điểm 2: Đem đến cho hội viên nhiều sự lựa chọn (cổng thanh
                toán, ví điện tử...)
              </li>
              <li class="notice-li">
                Ưu điểm 3: Rất tiện lợi, không mất nhiều phí giao dịch. Nhà cái
                khuyên dùng.
              </li>
            </ul>
          </div>
          <div class="notice-item right">
            <h4 class="notice-title">NẠP RÚT THÔNG THƯỜNG</h4>
            <ul class="notice-list">
              <li class="notice-li">
                Ưu điểm 1: Đây là cách nạp rút truyền thống nhất. An toàn, ổn
                định.
              </li>
              <li class="notice-li">
                Ưu điểm 2: Khách hàng trực tiếp giao dịch qua cổng thanh toán
                ngân hàng mà mình đang dùng, tạo nên được sự quen thuộc.
              </li>
              <li class="notice-li">
                Ưu điểm 3: Dễ thao tác, chắc hẳn các hội viên ai cũng sẽ thích
                điều này.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <main class="guide-body">
      <div class="body-inner">
        <div class="main-wrapper">
          <div class="nav-wrapper">
            <div
              v-for="(item, index) in titleList"
              :key="index"
              class="nav-item"
              :class="{ navActive: tabIndex === index, on: tabIndex === index }"
            >
              <ul class="nav-list">
                <li
                  v-for="(title, index2) in item"
                  :key="index2"
                  class="nav-li"
                  :class="{ on: titleIndex === index2 && tabIndex === index }"
                  @click="itemChange(index, index2)"
                >
                  {{ title.title }}
                </li>
                <!-- <li class="nav-li on">TÀI KHOẢN NGÂN HÀNG</li>
                <li class="nav-li">VIETTEL PAY</li>
                <li class="nav-li">MOMO</li>
                <li class="nav-li">THẺ CÀO ĐIỆN THOẠI</li>
                <li class="nav-li">QR CODE NGÂN HÀNG ></li>
                <li class="nav-li">ZALO</li> -->
              </ul>
            </div>
            <!-- <div class="nav-item on navActive">
              <ul class="nav-list">
                <li class="nav-li">NẠP TIỀN THƯỜNG VÀO TÀI KHOẢN 888B</li>
                <li class="nav-li">BANK</li>
                <li class="nav-li">MOMO</li>
              </ul>
            </div>
            <div class="nav-item on navActive">
              <ul class="nav-list">
                <li class="nav-li">RÚT TIỀN TỪ TÀI KHOẢN 888B</li>
              </ul>
            </div> -->
          </div>
          <div class="main-content">
            <div class="content-title">
              <i class="btn-left" :class="{on: tabIndex ===0 && titleIndex === 0 }" @click="nextUp"></i>
              <span> {{ title }} </span>
              <i class="btn-right" :class="{on1: tabIndex === (titleList.length - 1)&& (titleIndex === titleList[titleList.length - 1].length -1) }" @click="nextDown"></i>
            </div>
            <div class="inner-wrapper">
              <div v-if="tabIndex === 0">
                <template v-if="titleIndex === 0">
                  <ul>
                    <li>
                      Hiện tại nhà cái có 5 phương thức để nạp tiền trực tuyến
                      vào tài khoản:
                    </li>
                    <li>Nạp tiền nhanh qua Tài Khoản Ngân Hàng</li>
                    <li>Nạp tiền nhanh qua ViettelPay</li>
                    <li>Nạp tiền nhanh qua Momo</li>
                    <li>Nạp tiền nhanh qua QR Ngân Hàng</li>
                    <li>Nạp tiền nhanh thông qua Thẻ Cào Điện Thoại</li>
                    <li>
                      Nào, bây giờ chúng ta sẽ đi chi tiết cách làm từng phương
                      thức nạp tiền theo thứ tự từ trên xuống nhé.
                    </li>
                  </ul>
                </template>
                <template v-if="titleIndex === 1">
                  <p class="text">
                    Muốn nạp thành công tiền vào tài khoản 888b bằng phương thức
                    NẠP
                    <b>TÀI KHOẢN NGÂN HÀNG</b>
                    thì đảm bảo tài khoản của quý khách đã được đăng ký
                    “INTERNET BANKING“. Đảm bảo số dư trong tài khoản quý khách
                    đủ thưc hiện giao dịch. KHÔNG làm mới hoặc thoát trình duyệt
                    khi giao dịch đang thực hiện. Đặc biệt cần lưu lại
                    <b>MÃ GIAO DỊCH</b>
                    để đối chiếu khi cần thiết.
                  </p>
                  <p class="text">
                    Bước 1: Chọn vào mục
                    <b>NGÂN HÀNG</b> trên màn hình,chọn ngân hàng bạn muốn nạp
                    tiền, điền số điểm nạp, chọn thanh toán.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/bank-1.png" />
                  </div>
                  <p class="text">
                    Bước 2: Sau khi nhấp vào thanh toán màn hình sẽ chuyển sang
                    màn hình đăng nhập tài khoản ngân hàng của bạn, đợi OPT trả
                    về xác nhận hoàn tất thanh toán. Thông báo xác nhận sẽ được
                    gửi đến tài khoản của bạn sau khi bạn thực hiện thanh toán.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/bank-2.png" />
                  </div>
                  <p class="text">Những ngân hàng còn lại thao tác tương tự.</p>
                  <p class="text">
                    Nhà cái 888b hiện đang hỗ trợ 15 ngân hàng để quý hội viên
                    NẠP TIỀN TRỰC TUYẾN
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/bank-3.png" />
                  </div>
                </template>
                <template v-if="titleIndex === 2">
                  <p class="text">
                    Người dùng có tài khoản
                    <b>VIETTELPAY</b>
                    muốn dùng phương thức này để thanh toán chỉ cần hoàn thành
                    những bước cơ bản như sau:
                  </p>
                  <p class="text">
                    Bước 1: Chọn vào biểu tưởng
                    <b>VIETTELPAY</b>, chọn chính xác số điểm muốn nạp, sau đó
                    nhấp vào <b>THÔNG TIN CHUYỂN KHOẢN</b>.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/viettel-1.png" />
                  </div>
                  <p class="text">
                    <b>Bước 2</b>: Lưu lại mã
                    <b>QR Code</b>
                    hiện trên màn hình, tiếp theo đó bạn hãy mở ứng dụng
                    <b>VIETTELPAY</b>
                    lên chọn vào biểu tượng Thanh Toán QR Code rồi sau đó quét
                    mã QR Code đã lưu về máy, điền nội chính xác
                    <b>Nội Dung</b>
                    chuyển tiền, xác nhận số tiền, hoàn tất thanh toán.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/viettel-2.png" />
                  </div>
                  <div class="img-box">
                    <img src="../../assets/images/km/viettel-3.png" />
                  </div>
                </template>
                <template v-if="titleIndex === 3">
                  <p class="text">
                    Bước 1: Chọn vào biểu tưởng
                    <b>MOMO,</b>
                    điền số tiền muốn nạp, sau đó nhấp vào
                    <b>Bước Tiếp Theo</b>.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/momo-1.png" />
                  </div>
                  <p class="text">
                    <b>Bước 2:</b>
                    Lưu lại mã
                    <b>QR Code</b>
                    và
                    <b>Nội Dung</b>
                    hiện trên màn hình, tiếp theo đó bạn hãy mở ứng dụng
                    <b>MOMO</b>
                    lên chọn vào biểu tượng Thanh Toán QR Code rồi sau đó quét
                    mã QR Code đã lưu về máy, điền nội chính xác Nội Dung chuyển
                    tiền, xác nhận số tiền, hoàn tất thanh toán. Quý khách cũng
                    có thể thanh toán qua mã QR Code hoặc chuyển tiền qua số
                    điện thoại hiện bên trên.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/momo-2.png" />
                  </div>
                  <div class="img-box">
                    <img src="../../assets/images/km/momo-3.png" />
                  </div>
                  <p class="text three">
                    Lưu ý: Không được chỉnh sửa đổi số tiền khi đã và đang giao
                    dịch, mã QR Code chỉ quét 1 lần duy nhất, vui lòng nhập
                    chính xác nội dung mã giao dịch để lệnh nạp tiền của quý
                    khách được cập nhật nhanh chóng, bất kỳ sai sót nào phát
                    sinh sẽ không được giải quyết.
                  </p>
                </template>
                <template v-if="titleIndex === 4">
                  <p class="text one">
                    <b>Bước 1:</b>
                    Chọn vào biểu tưởng
                    <b>THẺ CÀO</b>, chọn số tiền muốn nạp, sau đó nhấp vào
                    <b>BƯỚC TIẾP THEO</b>.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/phone-1.png" />
                  </div>
                  <p class="text two">
                    <b>Bước 2:</b>
                    Chọn loại thẻ bạn có, điền số tiền muốn nạp, điền mã số thẻ
                    cào, điền mã số seri. Sau đó kiểm tra lại thông tin và nhấp
                    vào nút "Gửi" là hoàn tất thanh toán
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/phone-2.png" />
                  </div>
                  <p class="text three">
                    <b>Lưu ý 1</b>
                    : Đây là phương thức nạp tiền đơn giản nhất của 888b, chúng
                    tôi cam kết mang đến cho khách hàng sự tiện lợi nhất, tuy
                    nhiên với phương thức nạp tiền này thì người chơi sẽ bị trừ
                    một phần phí thẻ cào là 25% trên tổng tiền nạp. Ví dụ bạn
                    nạp 1,000,000 VNĐ vào tài khoản, sau khi trừ khí thẻ cào
                    (1,000,000 * 25% = 250.000) thì khách hàng được cập nhật
                    điểm trên hệ thống là 750 điểm, tương đương với 750.000 VNĐ.
                  </p>
                  <p class="text four">
                    <b>Lưu ý 2</b>
                    : 888b chỉ chấp nhận thẻ VIETTEL, VINAPHONE, MOBIPHONE,
                    VIETNAMMOBILE, quý khách vui lòng điền chính xác thông tin
                    loại thẻ, mệnh giá, mã thẻ và số seri vì bất kỳ trường hợp
                    sai sót nội dung nhập nào cũng sẽ KHÔNG được giải quyết.
                  </p>
                </template>
                <template v-if="titleIndex === 5">
                  <p class="text one">
                    Bước 1: Chọn vào biểu tưởng QR NGâN HÀNG , chọn NGÂN HÀNG
                    bạn đang có trong 5 ngân hàng 888b cung cấp, ĐIỀN số tiền
                    muốn nạp, sau đó nhấp vào THÔNG TIN CHUYỂN..
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/qr-1.png" />
                  </div>
                  <p class="text two">
                    Bước 2 : Lưu lại mã QR CODE và NỘI DUNGhiện trên màn hình,
                    tiếp theo đó bạn hãy mở ứng dụng QR NGÂN HÀNG HOẶC VÀO APP
                    CỦA NGÂN HÀNGsẽ thanh toán chọn THANH TOÁN QR CODE, chọn vào
                    biểu tượng THANH TOÁN QR CODE RỒI sau đó quét mã QR CODE đã
                    lưu về máy, điền chính xác NỘI DUNG CHUYỂN TIỀN, xác nhận số
                    tiền, hoàn tất thanh toán.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/qr-2.png" />
                  </div>
                  <p class="text three">
                    Lưu ý: Đối với cổng thanh toán online khách hàng sẽ tự chịu
                    phí 1.8-3% trên tổng số tiền nạp (tùy theo phương thức thanh
                    toán khách hàng chọn - sẽ được hiển thị ngay bên dưới khung
                    nhập số tiền).
                    <br />
                    Sau khi thao tác trên 888b, thông tin hiển thị trên giao
                    diện nạp sẽ không được sửa đổi số tiền, không được thêm bất
                    kỳ chữ cái hoặc ký tự nào ngoài 4 ký tự có sẵn. Đảm bảo quý
                    khách có tài khoản internet banking và số dư đủ thanh toán.
                  </p>
                  <p class="text four">
                    Nhà cái 888b hiện đang hỗ trợ 5ngân hàng dưới dây để thực
                    hiện thanh toán QR Ngân Hàng:
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/qr-3.png" />
                  </div>
                </template>
                <template v-if="titleIndex === 6">
                  <p class="text">
                    Bước 1: Chọn vào biểu tưởng
                    <b>ZALO,</b>
                    điền số tiền muốn nạp, sau đó nhấp vào
                    <b>Bước Tiếp Theo</b>.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/zalo-1.png" />
                  </div>
                  <p class="text">
                    <b>Bước 2:</b>
                    Lưu lại mã
                    <b>QR CODE</b>
                    và
                    <b>NỘI DUNG</b>
                    hiện trên màn hình, tiếp theo đó bạn hãy mở ứng dụng
                    <b>ZALO</b>
                    lên chọn vào biểu tượng Thanh Toán QR Code rồi sau đó quét
                    mã QR Code đã lưu về máy, điền nội chính xác Nội Dung chuyển
                    tiền, xác nhận số tiền, hoàn tất thanh toán. Quý khách cũng
                    có thể thanh toán qua mã QR Code hoặc chuyển tiền qua số
                    điện thoại hiện bên trên.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/zalo-2.jpg" />
                  </div>
                  <div class="img-box">
                    <img src="../../assets/images/km/zalo-3.jpg" />
                  </div>
                  <p class="text three">
                    Lưu ý: Không được chỉnh sửa đổi số tiền khi đã và đang giao
                    dịch, mã QR Code chỉ quét 1 lần duy nhất, vui lòng nhập
                    chính xác nội dung mã giao dịch để lệnh nạp tiền của quý
                    khách được cập nhật nhanh chóng, bất kỳ sai sót nào phát
                    sinh sẽ không được giải quyết.
                  </p>
                </template>
              </div>
              <div v-if="tabIndex === 1">
                <template v-if="titleIndex === 0">
                  <p class="text one">
                    Hai phương thức nạp tiền thông thường vào tài khoản 888b là
                    <b>NGÂN HÀNG</b>
                    và
                    <b>MOMO</b>
                    . Người chơi sẽ chuyển khoản qua
                    <b>NGÂN HÀNG</b> hoặc <b>MOMO</b> cho chủ tài khoản của nhà
                    cái 888b.
                  </p></template
                >
                <template v-if="titleIndex === 1">
                  <p class="text one">
                    Nhà cái 888b đang hỗ trợ 8 ngân hàng để hội viên có thể
                    <b>NẠP TIỀN THƯỜNG</b>
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/bank-normal-1.png" />
                  </div>
                  <p class="text two">
                    <b>Bước 1:</b>
                    Chọn vào mục
                    <b>BANK</b>
                    trên màn hình,chọn ngân hàng bạn dùng,
                    <b>ĐIỀN</b>
                    số điểm nạp, chọn
                    <b>Bước Tiếp Theo</b>
                    . Lưu lại thông tin
                    <b>Số Tài Khoản</b>
                    kèm
                    <b>Nội Dung</b>
                    chuyển khoản.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/bank-normal-2.png" />
                  </div>
                  <p class="text three">
                    <b>Bước 1:</b>
                    Chọn vào mục
                    <b>BANK</b>
                    trên màn hình,chọn ngân hàng bạn dùng,
                    <b>ĐIỀN</b>
                    số điểm nạp, chọn
                    <b>Bước Tiếp Theo</b>
                    . Lưu lại thông tin
                    <b>Số Tài Khoản</b>
                    kèm
                    <b>Nội Dung</b>
                    chuyển khoản.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/bank-normal-3.png" />
                  </div>
                  <p class="text four">
                    <b>Bước 2:</b>
                    Tiến hành dùng tài khoản ngân hàng online của bạn để chuyển
                    tiền vào tài khoản đã lưu ở trên, lưu ý khi chuyển tiền phải
                    nhập chính xác nội dung chuyển khoản. Sau đó bấm xác nhận để
                    hoàn tất giao dịch thanh toán.
                  </p>
                  <p class="text five">
                    <b>Lưu ý 1:</b>
                    Quý khách vui lòng chọn " phí ngoài " (người chuyển chịu phí
                    chuyển tiền). Chúng tôi sẽ cập nhật điểm cho quý khách theo
                    số tiền thực nhận. Để nạp tiền và lên điểm nhanh nhất, quý
                    khách vui lòng điền chính xác NỘI DUNG CHUYỂN KHOẢN (Chữ Màu
                    Vàng) khi quý khách chuyển khoản đến tài khoản của chúng
                    tôi.
                  </p>
                  <p class="text six">
                    <b>Lưu ý 2:</b>
                    Tài khoản ngân hàng của chúng tôi sẽ thường xuyên thay đổi
                    hoặc tạm ngừng dịch vụ bất cứ lúc nào, quý khách vui lòng
                    kiểm tra tài khoản ngân hàng mới nhất trong trang trước mỗi
                    lần nạp tiền. Quý khách không thể chuyển tiền đến số tài
                    khoản qua hình ảnh chụp lại hoặc lịch sử của những lần
                    chuyển tiền trước đó. Nếu quý khách nạp tiền không đúng theo
                    quy định trên, chúng tôi có quyền không xử lý và không chịu
                    trách nhiệm đối với khoản tiền quý khách nạp không theo quy
                    định của chúng tôi.
                  </p>
                </template>
                <template v-if="titleIndex === 2">
                  <p class="text one">
                    <b>Bước 1</b>: Chọn vào biểu tượng <b>MOMO</b> trên màn
                    hình, <b>ĐIỀN</b> số điểm cần nạp. Có 2 phương thức nạp tiền
                    là <b>QUÉT MÃ THANH TOÁN</b> và <b>CHUYỂN KHOẢN MOMO.</b
                    ><br />
                    <b>QUÉT MÃ MOMO</b>: Hội viên chỉ cần mở <b>MOMO</b> cá
                    nhân, quét mã <b>QR</b> trên trang web hiển thị và thực hiện
                    chuyển tiền.<br />
                    <b>CHUYỂN KHOẢN MOMO</b>: Hội viên dùng <b>MOMO</b> cá nhân
                    thực hiện chuyển tiền vào thông tin tài khoản mà trang web
                    đang hiển thị.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/momo-normal-1.png" />
                  </div>
                  <p class="text two">
                    Bước 2: Tiến hành chuyển khoản online, vào APP MOMO của bạn
                    chuyển khoản cho mã QR Code hoặc Account (tùy bạn chọn),
                    điền Chính Xác nội dung chuyển khoản để hệ thống kiểm tra
                    khớp lệnh sẽ được duyệt ngay lập tức và bấm vào Xác Nhận để
                    hoàn tất thanh toán. Hội viên hết sức lưu ý: Tài khoản và QR
                    Code và Account Transfer được thay đổi thường xuyên vì vậy
                    trước khi thanh toán bạn luôn phải cập nhật thông tin chính
                    xác, tránh trường hợp chuyển sai chuyển nhầm ảnh hưởng đến
                    lợi ích của cả đôi bên.
                  </p>
                </template>
              </div>
              <div v-if="tabIndex === 2">
                <template v-if="titleIndex === 0">
                  <p class="text one">
                    Bước đầu tiên anh em cần có cho mình một tài khoản ngân hàng
                    vào tài khoản 888b để rút tiền. Mình khuyên anh em là nên
                    tạo riêng cho mình một tài khoản dành cho việc rút tiền 888b
                    để bảo mật về nhiều vấn đề.
                    <br />Thêm tài khoản trên máy tính và điện thoại gần giống
                    nhau Để thêm tài khoản ngân hàng thì anh em làm những bước
                    sau:
                  </p>
                  <p class="text two">
                    <b>Bước 1:Đăng nhập vào tài khoản và chọn mục</b>
                    thông tin cá nhân thêm thông tin thẻ ngân hàng.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/withdraw-1.png" />
                  </div>
                  <p class="text three">
                    Sau khi vào mục thông tin cá nhân, anh em vào thẻ tài khoản
                    ngân hàng và bấm vào biểu tượng dấu cộng, sau đó nhập vào số
                    thẻ và ngân hàng bạn đang dùng rồi lưu lại để hoàn tất việc
                    thêm tài khoản ngân hàng.
                    <br />
                    Thêm tài khoản ngân hàng.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/withdraw-2.png" />
                  </div>
                  <p class="text four">
                    Đó là các bước để hoàn tất việc thêm tài khoản ngân hàng.
                    Nếu anh em thêm trên điện thoại di động thì cũng vậy chỉ cần
                    vào mục thông tin cá nhân. Sau đó chọn mục tài khoản ngân
                    hàng rồi làm các bước tiếp theo giống như hướng dẫn trên thì
                    anh em sẽ dễ dàng thêm được tài khoản ngân hàng.
                    <br />
                    <b>Lưu ý:</b>
                    <br />Để đảm bảo an toàn cho tài khoản và nhanh chóng nhận
                    tiền khi rút, bạn cần liên kết với tài khoản ngân hàng chính
                    chủ có cùng tên thật với tài khoản của bạn. Bạn có thể liên
                    kết tối đa 5 tài khoản ngân hàng khác nhau có cùng tên thật
                    với tài khoản của bạn. Để đảm bảo an toàn cho tài khoản của
                    quý khách, chúng tôi không xử lý trường hợp tài khoản ngân
                    hàng không trùng với tên thật của quý khách.
                  </p>
                  <p class="text five">
                    <b
                      >Bước 2: Tiến hành rút tiền từ tài khoản 888b về tài khoản
                      ngân hàng đã thêm</b
                    >
                    <br />Thật là dễ dàng cho anh em khi đã thêm tài khoản thành
                    công khi thức hiện bước rút tiền. Mình rất thích nhà cái này
                    vì khi rút tiền nó rất đơn giản chỉ cần vào mục rút tiền
                    xong chọn tài khoản của mình. Nhập số tiền mình thắng và rút
                    ra thôi.
                    <br />
                    <b>Bước 1:</b> Đăng nhập 888b, sau đó chọn nút
                    <b>Rút tiền</b>
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/withdraw-3.png" />
                  </div>
                  <p class="text six">
                    <b>Bước 2:</b>
                    Tại giao diện hội viên, bạn sẽ thấy chức năng rút tiền. Bạn
                    cần chọn ngân hàng muốn giao dịch, nhập số tiền muốn rút sau
                    đó ấn xác nhận là xong.
                    <b>Lưu ý mệnh giá quy đổi là 1 = 1000 VNĐ</b>. <br />Nếu bạn
                    chưa có tài khoản ngân hàng thì cần phải nhập vào mới rút
                    tiền được.
                  </p>
                  <div class="img-box">
                    <img src="../../assets/images/km/withdraw-4.png" />
                  </div>
                  <p class="text eleven">
                    <b>Lưu ý:</b>
                    <br />Trong một lần rút tối thiểu là 200 điểm (200,000 VND)
                    và tối đa 1,000,000 điểm (tương đương với 1 tỷ VND).
                    <br />Nhằm tạo sự thuận lợi lớn nhất cho hội viên 888b không
                    giới hạn số tiền rút trong ngày của hội viên. <br />Tuy
                    nhiên khi cần rút với số tiền lớn, hội viên vui lòng thông
                    báo cho chuyên viên hoặc bộ phận CSKH để được phục vụ một
                    cách tốt nhất! <br />888b sẽ xác minh thông tin trong lần
                    rút đầu để đảm bảo sự an toàn tài khoản cao nhất cho hội
                    viên. <br />Nếu hội viên có tham dự các chương trình khuyến
                    mãi, số tiền rút phải phù hợp với qui định của chương trình
                    đó.
                  </p>
                  <p class="text eight">
                    <b>Kết luận</b>
                    <br />Trên đây là hướng dẫn tất tần tật hình thức và các
                    bước thực hiện rút tiền tại nhà cái
                    <b>888b.com</b>
                    , nếu hội viên còn thắc mắc gì về
                    <b>RÚT TIỀN</b>
                    của trang thì có thể liên hệ hỗ trợ trực tiếp 24h online !!!
                    Chúc anh em thành công và nhận được nhiều may mắn !!!
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleList: [
        [
          { title: "NẠP TIỀN TRỰC TUYẾN VÀO TÀI KHOẢN 888B" },
          { title: "TÀI KHOẢN NGÂN HÀNG" },
          { title: "VIETTEL PAY" },
          { title: "MOMO" },
          { title: "THẺ CÀO ĐIỆN THOẠI" },
          { title: "QR CODE NGÂN HÀNG " },
          { title: "ZALO" },
        ],
        [
          { title: "NẠP TIỀN THƯỜNG VÀO TÀI KHOẢN 888B" },
          { title: "BANK" },
          { title: "MOMO" },
        ],
        [{ title: "RÚT TIỀN TỪ TÀI KHOẢN 888B" }],
      ],
      tabIndex: 0,
      titleIndex: 0,
      title: "",
    };
  },
  mounted() {
    this.itemChange(0, 0);
  },
  methods: {
    itemChange(val1, val2) {
      this.tabIndex = val1;
      this.titleIndex = val2;
      this.title = this.titleList[this.tabIndex][this.titleIndex].title;
    },
    nextDown() {
      if (this.tabIndex === this.titleList.length - 1) return;
      if (
        this.tabIndex <= this.titleList.length - 1 &&
        this.titleIndex === this.titleList[this.tabIndex].length - 1
      ) {
        this.tabIndex++;
        this.titleIndex = 0;
      } else {
        this.titleIndex++;
      }
      this.title = this.titleList[this.tabIndex][this.titleIndex].title;
    },
    nextUp() {
      if (this.tabIndex === 0 && this.titleIndex === 0) return;
      if (this.tabIndex > 0 && this.titleIndex === 0) {
        this.tabIndex--;
        this.titleIndex = this.titleList[this.tabIndex].length - 1;
      } else {
        this.titleIndex--;
      }
      this.title = this.titleList[this.tabIndex][this.titleIndex].title;
    },
  },
};
</script>
<style lang="less" scoped>
.guide-wrapper {
  width: 100%;
  height: auto;
  background-color: #181818;
  .guide-head {
    background: url(../../assets/images/km/bg2.png) no-repeat center top / 100%
      100%;
    .head-inner {
      width: 1200px;
      margin: 0 auto;
      max-width: 1200px;
      height: auto;
      .guide-head-title {
        height: 47px;
        font-size: 40px;
        font-weight: 400;
        color: #f3c042;
        line-height: 41px;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        padding: 89px 0 54px;
      }
      .guide-head-desc {
        height: 54px;
        font-size: 19px;
        line-height: 27px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        padding-left: 40px;
        padding-right: 35px;
      }
      .guide-head-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        transform: translateX(-20px);
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          img {
            border: 0;
            vertical-align: middle;
            display: block;
            width: 100%;
          }
        }
      }
      .notice-wrapper {
        display: flex;
        margin: 58px 0 0;
        background: linear-gradient(
            to bottom,
            transparent 5%,
            #8e8e8e,
            transparent 100%
          )
          no-repeat center center / 2px 100%;
        .notice-item {
          text-align: center;
          .notice-title {
            text-align: center;
            height: 38px;
            font-size: 30px;
            font-weight: bold;
            color: #ffffff;
            text-transform: uppercase;
            border-bottom: 1px solid #fdbe00;
            padding: 0 54px 19px;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            position: relative;
            display: inline-block;
            margin: 0;
            &::before {
              content: "";
              display: block;
              width: 46px;
              height: 29px;
              position: absolute;
              top: 50%;
              left: -8px;
              margin-top: -23px;
              background: url(../../assets/images/km/zs.png) no-repeat center /
                contain;
            }
            &::after {
              content: "";
              display: block;
              width: 46px;
              height: 29px;
              position: absolute;
              top: 50%;
              right: -8px;
              margin-top: -23px;
              background: url(../../assets/images/km/zs.png) no-repeat center /
                contain;
            }
          }
          .notice-list {
            list-style: none outside none;
            margin-top: 23px;
            margin-right: 0 50px 0 0;
            padding: 0;
            .notice-li {
              width: 573px;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              text-align: left;
              line-height: 32px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .left {
          margin-right: 50px;
        }
      }
    }
  }
  .guide-body {
    width: 100%;
    height: auto;
    background: url(../../assets/images/km/bg2.png) no-repeat center top / 100%
      auto;
    padding: 82px 0 50px;
    .body-inner {
      width: 1200px;
      max-width: 1200px;
      height: auto;
      margin: 0 auto;
      .main-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        min-height: 560px;
        .nav-wrapper {
          height: auto;
          position: absolute;
          left: 0;
          top: 0;
          .nav-item {
            width: 353px;
            background: #252525;
            height: 70px;
            overflow: hidden;
            .nav-list {
              position: relative;
              list-style: none outside none;
              margin: 0;
              padding: 0;
              .nav-li {
                height: 70px;
                line-height: 1.2em;
                vertical-align: top;
                text-align: left;
                padding: 0 0 0 38px;
                font-size: 21px;
                font-weight: 400;
                color: #ffffff;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin: 0;
                box-sizing: border-box;
              }
              .on {
                background-color: #fff;
                font-weight: 400;
                color: #2d2d2d;
              }
            }
          }
          .on {
            width: 366px;
            height: auto;
            background-color: #464646;
            overflow: visible;
          }
          .navActive {
            .nav-list {
              &::before {
                content: "";
                top: 0;
                display: block;
                height: 70px;
                width: 12px;
                position: absolute;
                left: -12px;
                background-color: #fbb330;
                border-radius: 13px 0px 0px 13px;
              }
              .nav-li {
                &:first-child {
                  background: linear-gradient(132deg, #fbb330 0%, #ffd904 100%);
                  font-size: 19px;
                  font-weight: 400;
                  color: #000000;
                }
              }
            }
          }
        }
        .main-content {
          margin-left: 366px;
          width: 814px;
          background-color: #252525;
          min-height: 933px;
          padding: 0 47px;
          .content-title {
            margin: 0 auto;
            height: 102px;
            text-align: center;
            line-height: 102px;
            font-size: 24px;
            font-weight: 400;
            color: #ffffff;
            border-bottom: 1px solid #ffd215;
            position: relative;
            text-transform: uppercase;
            i {
              position: absolute;
              top: 50%;
              margin-top: -20px;
              width: 24px;
              height: 40px;
            }
            .btn-left {
              left: 0;
              background: url(../../assets/images/km/guide_right_i.png)
                no-repeat center / contain;
              transform: rotate(-180deg);
            }
            .btn-right {
              right: 0;
              background: url(../../assets/images/km/guide_right_i.png)
                no-repeat center / contain;
            }
            .on {
              background-image: url(../../assets/images/km/guide_right.png);
              transform: rotate(0);
            }
            .on1 {
              background-image: url(../../assets/images/km/guide_right.png);
              transform: rotate(-180deg);
            }
          }
          .inner-wrapper {
            margin-top: 27px;
            padding-bottom: 91px;
            img {
              border: 0;
              vertical-align: middle;
              display: block;
            }
            ul {
              list-style: none outside none;
              margin: 0;
              padding: 0;
              li {
                font-size: 18px;
                font-weight: 400;
                color: #ffffff;
                line-height: 1.8em;
                margin: 0;
                padding: 0;
              }
            }
            .one {
              margin-top: 20px;
            }
            .text {
              letter-spacing: -0.1px;
              line-height: 26px;
              font-size: 17px;
              font-weight: 400;
              color: #ffffff;
              text-align: left;
              b {
                font-weight: bold;
              }
            }
            .two {
              margin-top: 33px;
            }
            .img-box {
              display: flex;
              justify-content: center;
              margin: 33px 0;
              img {
                width: 100%;
              }
            }
            .five {
              margin-top: 2em;
            }
            .eight {
              margin-top: 2em;
            }
          }
        }
      }
    }
  }
}
</style>